.couponSearchCover {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;
  display: flex;
}

.couponSearchModal {
  display: flex;
  flex-direction: column;
  height: 90%;
  width: 75%;
  background-color: white;
  margin: auto;
  padding: 20px;
  border-radius: 10px;
  overflow-y: auto;
}

.couponSearchInput {
  display: grid;
  grid-template-columns: 1fr 10rem;
  align-items: center;
}

.searchButton {
  background-color: #eee;
  color: 222;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  cursor: pointer;
  height: 2rem;
  width: 6rem;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.recordButton {
  background-color: #eee;
  color: 222;
  border: none;
  padding: 0.5rem;
  border-radius: 5px;
  cursor: pointer;
  height: 1.5rem;
  width: 3rem;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.resultsContainer {
  overflow-y: auto;
  border: 1px solid #ccc;
  margin-top: 10px;
}

.resultsTable {
  width: 100%;
  border-collapse: collapse;
}

.resultsTable th, .resultsTable td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.resultsTable th {
  background-color: #f4f4f4;
  position: sticky;
  top: 0;
}

.activeCouponInput {
  width: 4rem;
  font-size: 1.2rem;
  border: 1px solid #ccc;
  border-radius: 0.5rem;
  padding: 0.25rem 0.5rem;
}